<template>
  <div class="wrapper">
    <div class="container">
      <div class="section__line">
        <div class="link__top">
          <p class="link__btn pointer" @click="$router.push('/contract')">
            <img src="../assets/img/list_checklist-icon.svg" /> Заключить договор
          </p>
        </div>
      </div>

      <div class="section__line">
        <div class="section__bg section--bank__info">
          <div class="bank__info--top">
            <div class="bank__info--name" v-if="GET_USER_DATA.organization">
              {{ GET_USER_DATA.organization.name }}
            </div>
            <div class="bank__info--btn" @click="$router.push('/update')">
              <div class="bank__btn--item bank__info--edit">
                <a>
                  <img src="../assets/img/edit-icon.svg" />
                </a>
              </div>
            </div>
          </div>
          <div class="bank__info--miniinfo">
            <div class="bank__miniinfo">
              <div
                class="bank__miniinfo--item"
                v-if="
                  GET_USER_DATA.admin &&
                  GET_USER_DATA.organization &&
                  GET_USER_DATA.organization.phone != 'null'
                "
              >
                <a>{{ GET_USER_DATA.organization.phone }}</a>
              </div>
              <div
                class="bank__miniinfo--item"
                v-if="
                  GET_USER_DATA.admin &&
                  GET_USER_DATA.organization &&
                  GET_USER_DATA.organization.email != 'null'
                "
              >
                <a>{{ GET_USER_DATA.email }}</a>
              </div>
              <div
                class="bank__miniinfo--item"
                v-if="
                  GET_USER_DATA.admin &&
                  GET_USER_DATA.organization &&
                  GET_USER_DATA.organization.website != 'null'
                "
              >
                <a>{{ GET_USER_DATA.organization.website }}</a>
              </div>
              <div
                class="bank__miniinfo--item"
                v-if="
                  GET_USER_DATA.admin &&
                  GET_USER_DATA.organization &&
                  GET_USER_DATA.organization.address != 'null'
                "
              >
                {{ GET_USER_DATA.organization.address }}
              </div>
            </div>
          </div>
          <div
            class="bank__info--fullinfo"
            v-bind:class="{ 'fullinfo--active': openInfo == true }"
          >
            <!-- fullinfo--active -->
            <div class="fullinfo--block">
              <ul>
                <li v-if="GET_USER_DATA.organization">
                  <span class="fullinfo--title"> БИН </span>
                  <span class="fullinfo--text">
                    {{ GET_USER_DATA.organization.bin }}
                  </span>
                </li>
                <li v-if="GET_USER_DATA.organization">
                  <span class="fullinfo--title"> ИИК </span>
                  <span
                    class="fullinfo--text"
                    v-if="GET_USER_DATA.organization.iik != 'null'"
                  >
                    {{ GET_USER_DATA.organization.iik }}
                  </span>
                </li>
                <li v-if="GET_USER_DATA.organization">
                  <span class="fullinfo--title"> БИК </span>
                  <span
                    class="fullinfo--text"
                    v-if="GET_USER_DATA.organization.bik != 'null'"
                  >
                    {{ GET_USER_DATA.organization.bik }}
                  </span>
                </li>
                <li>
                  <span class="fullinfo--title"> Файлы: </span>
                </li>

                <div class="item__row" v-if="GET_USER_DATA.organization">
                  <div
                    class="item__row organization__files"
                    v-for="file in GET_USER_DATA.organization.files"
                    :key="file.id"
                  >
                    <div class="item__column mr-2">
                      <i class="mdi mdi-file-document-multiple-outline"></i>
                      <p
                        class="pointer"
                        @click="downloadOrganizationFile(file.id, file.fileName)"
                      >
                        {{ file.fileName }}
                      </p>
                      <div
                        class="item__row item__ac pointer"
                        @click="deleteFile(file.id)"
                      >
                        <i class="mdi mdi-trash-can-outline"></i>
                        <p class="mb-0">Удалить</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>

          <div class="bank__info--fullinfo-arrow" @click="hideInfo()">
            <i class="mdi mdi-chevron-up" style="font-size: 33px" v-if="openInfo"> </i>

            <i class="mdi mdi-chevron-down" style="font-size: 33px" v-else> </i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      openInfo: false,
    };
  },
  created() {},

  methods: {
    ...mapActions(["USER_DATA"]),
    deleteFile(id) {
      this.$axios({
        method: "delete",
        url: this.$API_URL + this.$API_VERSION + "user/organization/delete/file?id=" + id,
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          console.log(response);
          this.USER_DATA();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadOrganizationFile(id, name) {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "organization/file?id=" + id,
        data: this.sobs,
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
        // responseType: 'arraybuffer'
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    hideInfo() {
      this.openInfo = !this.openInfo;
    },
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
};
</script>
